import * as React from "react"
import Common from "../components/main/common"
import * as styles from "../styles/contact.module.scss"
import axios from 'axios'
import {notify} from 'react-notify-toast';

export default class ContactPage extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        sender: "",
        senderEmail: "",
        senderMessage: "",
      }
    }

    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      this.setState({
        [name]: value,
      })
    }

    validateInput(sender, senderEmail, senderMessage){
      return (sender && senderEmail && senderMessage)
    }

    handleSubmit = event => {
      event.preventDefault();

      if(this.validateInput(this.state.sender, this.state.senderEmail, this.state.senderMessage)){
        axios.post(process.env.mail_sender + 'contact', {
          sender_name: this.state.sender,
          sender_email: this.state.senderEmail,
          sender_message: this.state.senderMessage
          }).then(response => {
            const notificationColor = { background: "#28a74585", text: "#FFFFFF" };
            this.state.sender = "";
            this.state.senderEmail = "";
            this.state.senderMessage = "";
            notify.show('Your message has been sent!', "custom", 5000, notificationColor);
        });
      }else{
        console.log('incomplete input');
      }
    }

    render (){
      return(
        <main>
          <title>Get In Touch</title>
          <div className={styles.background}>
              <img alt="" src="https://source.unsplash.com/user/erondu/1600x500/?technology,contact" />
          </div>
          <Common>
            <div className={styles.contentWrapper}>
              <div className={styles.contactHead}>Get in touch!</div>
              <div className={styles.contactSubhead}>Fill up the form below or send an email to <span>sayhi@thecasualdev.net</span></div>
              <form onSubmit={this.handleSubmit} className={styles.contactForm}>  
                <label>
                  <div>Name</div>
                  <input type="text" name="sender" value={this.state.sender} onChange={this.handleInputChange} />
                </label>
                <label>
                  <div>Email address</div>
                  <input type="email" name="senderEmail" value={this.state.senderEmail} onChange={this.handleInputChange} />
                </label>
                <label>
                  <div>Message</div>
                  <textarea  type="text" name="senderMessage" value={this.state.senderMessage} onChange={this.handleInputChange} />
                </label>
                <button type="submit">Send a message</button>
              </form>
            </div>
          </Common>
        </main>
      )
    }
  }